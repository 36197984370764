require('es6-promise').polyfill()

class MainVisualPlayer {
  constructor (id = '', opts = {}) {
    this._player = document.getElementById(id)
    this._isActive = false

    this._player.$player = this

    this._player.addEventListener('canplaythrough', e => {
      e.target.play()
      e.target.$player.isActive = true
    })
  }

  deploy () {
    if (this._player.tagName !== 'VIDEO') return

    this._player.innerHTML = ''
    this._player.appendChild(this._source)
    this._player.load()
  }

  setOnResize () {
    const reload = () => this._reload()

    return window.addEventListener('resize', async () => {
      let onresize
      clearTimeout(onresize)
      await new Promise(resolve => onresize = setTimeout(resolve, 200))
      return reload()
    })
  }

  async _reload () {
    const current = this._player.currentSrc

    if (!current) return
    if (new RegExp(this._src).test(current)) return

    this.isActive = false
    await new Promise(resolve => setTimeout(resolve, 500))
    return this.deploy()
  }

  get _source () {
    const source = document.createElement('source')
    source.setAttribute('src', this._src)
    source.setAttribute('type', 'video/mp4')
    return source
  }

  get _src () {
    return this._player.getAttribute(`data-src-${this._layout}`)
  }

  get _layout () {
    return (document.body.clientWidth < 600) ? 'sp' : 'pc'
  }

  get isActive () {
    return this._isActive
  }

  set isActive (bool) {
    bool = !!bool
    this._player.classList[(bool ? 'add' : 'remove')]('active')
    return this._isActive = bool
  }
}

const player = new MainVisualPlayer('mvplayer')
player.setOnResize()
player.deploy()
